.slider-container {
    position: relative;
    overflow: hidden;
}

.slides {
    transition: transform 0.3s ease-in-out;
}

.slide {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.prev {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.next {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}
